<template>
  <div class="table_view">
    <table class="w-100">
      <tr class="header">
        <th
          v-for="h in headerList"
          :key="h.label"
          :class="h.classH ? h.classH : ''"
          @click="onSort(h)"
        >
          <p
            class="mb-0"
            :class="{
              'd-flex justify-content-center noWrap':
                h.id !== 'HerstellerModellName'
            }"
          >
            <span style="min-width: 20px"></span>
            {{ h.label }}
            <table-arrow :name="sortT[h.id]" />
          </p>
        </th>
      </tr>
      <template v-for="(item, idx) in getList">
        <template v-if="!isPurchase">
          <table-view :key="`${idx}${item._id}`"
                      @favChanged="setItems(index, idx)"
                      :item="item"
                      :class="idx % 2 ? 'bg_gray' : 'bg_white'"
          />
        </template>

        <template v-else>
          <TableViewBuyer :key="`${idx}${item._id}`"
                          :item="item"
                          :class="idx % 2 ? 'bg_gray' : 'bg_white'"
          />
        </template>
      </template>
    </table>
  </div>
</template>
<script>
import TableView from '@/components/common/TableView.vue'
import TableViewBuyer from '../buyer/TableViewBuyer.vue'
import TableArrow from './TableArrow.vue'

export default {
  components: { TableView, TableArrow, TableViewBuyer },
  props: {
    getList: {
      required: true,
      type: Array,
      default: () => []
    },
    tableView: {
      type: Number,
      default: 0
    },
    sortT: {
      type: Object,
      default: null
    }
  },
  computed: {
    isPurchase() {
      return this.$route.name === 'buyerModels'
    }
  },
  data() {
    return {
      headerList: [
        { id: 'name', classH: 'txt_h', label: 'Modell' },
        { id: 'modelId', label: 'Modellnummer' },
        { id: null },
        { id: 'status', label: 'Status', bl: true },
        { id: 'PreisbindungVon', label: 'Gültig ab', date: true, cond: true },
        { id: 'PreisbindungBis', label: 'Gültig bis', date: true, cond: true },
        {
          id: 'pricelistUpdatedAt',
          label: 'Z.bearbeitet',
          date: true,
          bl: true,
          cond: true
        },
        { id: 'van', classH: 'van_wrap', label: 'VAN' },
        { id: 'tz', label: 'TZ info', bl: true, cond: true },
        { id: 'isExclusive', label: 'Exklusive', bl: true, cond: true },
        { id: 'Handelsmarken', label: 'Marke', bl: true },
        { id: null },
        { id: '03_em', label: 'Preisliste' },
        { id: 'isFavorite', label: 'Favoriten', bl: true, cond: true },
        { id: '04_em', classH: 'details_column', label: '' }
      ]
    }
  },
  methods: {
    onSort(h) {
      if (h.id.charAt(0) !== '0') {
        this.$emit('sortT', h.id, { date: h.date, bl: h.bl, cond: h.cond })
      }
    }
  },
  created() {
    if (this.isPurchase) {
      this.headerList[2] = {
        id: 'HerstellerModellName',
        classH: 'txt_h',
        label: 'Hersteller Modellname'
      }
      this.headerList[10] = {
        id: '02_em',
        classH: 'text-center',
        label: 'Faktura'
      }
      this.headerList[11] = { id: '01_em', label: 'Netto/Netto' }
      // remove 12th element
      this.headerList.splice(12, 1)
    } else {
      this.headerList = this.headerList.filter(h => h.id)
    }
  }
}
</script>
<style lang="scss">
.table_view {
  width: 100%;
  margin-bottom: 2em;

  table {
    background-color: #ee7f00;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    table-layout: fixed;
    width: 100px;
  }

  th {
    text-align: center;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
  }

  table td {
    width: 90px;
    padding: 10px;
    font-weight: 700;
    color: #555555;
    text-align: center;
  }

  .bg_gray {
    background-color: #f9f9f9;
  }

  .bg_white {
    background-color: #ffffff;
  }

  .noWrap {
    white-space: nowrap;
  }

  .txt_h {
    width: 105px;
  }

  .details_column {
    width: 40px;
  }

  .van_wrap {
    width: 130px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .txt_wrap {
    max-width: 105px;
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1680px) {
  .table_view {
    .txt_wrap {
      max-width: 100px;
    }

    .txt_h {
      width: 100px;
    }

    table td,
    th {
      font-size: 12px;
      padding: 5px;
    }
  }
}

@media screen and (max-width: 1420px) {
  .table_view {
    .txt_wrap {
      max-width: 80px;
    }

    .txt_h {
      width: 80px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .table_view {
    .txt_wrap {
      max-width: 70px;
    }

    .txt_h {
      width: 70px;
    }

    table td,
    th {
      font-size: 10px;
    }
  }
}
</style>
